import { TPlayerStates } from '@canalplus/oneplayer-types';
import { OnePlayerPlatform } from '@canalplus/sdk-core';
import { RefObject, useRef } from 'react';
import { useSelector } from 'react-redux';
import type { PlayerPlatform } from '../../../../constants/playerPlatforms';
import { getDeviceTypeFromPlatform } from '../../../../helpers/application/application-helper';
import {
  langKeySelector,
  offerLocationSelector,
  offerZoneSelector,
  platformSelector,
} from '../../../../selectors/application/application-selectors';
import { setIsEnded, setIsPaused, setIsPlayerReadyToPlay } from '../../context/actions';
import { useVideoDispatch } from '../../context/hooks/useVideoDispatch';
import { useGetPlayerCredentials } from '../../hooks/useGetPlayerCredentials';

type UseGetPlayerInstanceParams = {
  /**
   * The contentId or the url (if no DRM) of the content needed by OnePlayer
   */
  contentIdOrUrl: string;
  /**
   * The format of the video
   */
  format: PlayerPlatform | 'manual-hls';
  /**
   * If true, contentIdOrUrl is used as a contentID
   */
  isEncrypted?: boolean;
  /**
   * If true, displays subtitles
   */
  hasSubtitles?: boolean;
  /**
   * If true, the video will indefinitely loop
   */
  isLoop?: boolean;
  /**
   * If the value is changing, the player will be updated with the new one
   */
  isMuted: boolean;
  /**
   * To be performed when the sound value change in the Player
   * E.g. used to dispatch to the store to save the value of sound for others trailers
   */
  onSoundChange?: (value: boolean) => void;
};

type UseGetPlayerInstanceReturn = {
  getPlayerInstance: () => any;
  playerContainerRef: RefObject<HTMLDivElement>;
  subtitlesContainerRef: RefObject<HTMLDivElement>;
};

export function useGetPlayerInstance({
  contentIdOrUrl,
  format,
  isEncrypted = false,
  hasSubtitles = false,
  isLoop = false,
  isMuted,
  onSoundChange,
}: UseGetPlayerInstanceParams): UseGetPlayerInstanceReturn {
  const playerContainerRef = useRef<HTMLDivElement>(null);
  const subtitlesContainerRef = useRef<HTMLDivElement>(null);

  const platform = useSelector(platformSelector);
  const offerZone = useSelector(offerZoneSelector);
  const langKey = useSelector(langKeySelector);
  const offerLocation = useSelector(offerLocationSelector);
  const credentials = useGetPlayerCredentials();
  const videoDispatch = useVideoDispatch();

  const getPlayerInstance = () => {
    if (!(global as any).OnePlayerMinimal) {
      // eslint-disable-next-line no-console
      console.info('[Video]: OnePlayerMinimal is not defined');
      return undefined;
    }

    // eslint-disable-next-line no-console
    console.info('[Video]: OnePlayerMinimal new instance');

    const instance = new (global as any).OnePlayerMinimal(
      playerContainerRef.current,
      {
        autoMode: true,
        content: isEncrypted ? contentIdOrUrl : undefined,
        context: OnePlayerPlatform[platform],
        deviceType: getDeviceTypeFromPlatform(platform),
        params: {
          autoplay: false,
          loop: isLoop,
          muted: isMuted,
          offerZone,
          preferredSubtitle: hasSubtitles ? undefined : null, // if undefined onePlayer will select display subtitles, if null onePlayer will not display subtitles
          startAt: { position: 0 },
          trailerPreviewMode: true,
          url: !isEncrypted ? contentIdOrUrl : undefined,
        },
        platform: format,
        uiLanguage: `${langKey}-${String(offerLocation).toUpperCase()}`,
      },
      credentials,
      subtitlesContainerRef.current
    );

    // To synchronize isMuted between trailers
    if (onSoundChange) {
      instance.addEventListener('volumeChange', (value: any) => {
        onSoundChange(value.muted);
      });
    }

    instance.addEventListener('playerStateChange', (playerState: TPlayerStates) => {
      switch (playerState) {
        case 'LOADED':
          videoDispatch(setIsPlayerReadyToPlay(true));
          break;

        case 'PLAYING':
          videoDispatch(setIsPaused(false));
          break;

        case 'PAUSED':
          videoDispatch(setIsPaused(true));
          break;

        case 'ENDED':
          if (!isLoop) {
            videoDispatch(setIsEnded(true));
          }
          break;

        default:
          break;
      }
    });

    return instance;
  };

  return { getPlayerInstance, playerContainerRef, subtitlesContainerRef };
}
