import { getCookie } from '@canalplus/mycanal-commons';
import { IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import { useSelector } from 'react-redux';
import { CookieKey } from '../../../constants/cookie';
import { getEncodedPassId } from '../../../helpers/user/user-helper';
import { tokenCMSSelector } from '../../../selectors/application/application-selectors';
import {
  abTestingPopulationSelector,
  microEligibilitySelector,
  passTokenSelector,
  profileIdSelector,
} from '../../../store/slices/user-selectors';

export const useGetPlayerCredentials = (): IAPICredentialsRaw => {
  const [deviceId, , trackingKeyId] = getCookie(CookieKey.DeviceId)?.split(':') || [];

  return {
    abTestPopulation: String(useSelector(abTestingPopulationSelector) || ''),
    appSessionId: getCookie(CookieKey.SessionId),
    deviceId,
    microEligibility: useSelector(microEligibilitySelector),
    passId: getEncodedPassId(),
    passToken: useSelector(passTokenSelector),
    profileId: useSelector(profileIdSelector)?.toString(),
    tokenCMS: useSelector(tokenCMSSelector),
    trackingKeyId,
  };
};
